import React, { useState } from 'react';
import { Rating, ThinStar } from '@smastrom/react-rating';
import '@smastrom/react-rating/style.css'; // Import the default styles

// Declare the styles outside your component so they don't get re-created
const myStyles = {
  itemShapes: ThinStar,
  activeFillColor: '#000000',
  inactiveFillColor: '#E3DCD7',
};

function TotalRating() {
  const [rating, setRating] = useState(0);

  return (
    <div className=''>
     
      <Rating
        style={{ width: '100px' }}
        value={rating}
        onChange={setRating}
        itemStyles={myStyles}
      />
      <p className='text-[#AB3430] flex justify-between  w-full'>Overall rating: {rating}</p>
    </div>
  );
}

export default TotalRating;
