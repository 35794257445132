import React, { useState } from 'react';
import SelfCheckout from './SlefCheckout';
import GetCheckoutAdmin from './GetCheckoutAdmin';

const CheckoutAdmin = () => {
  const [view, setView] = useState('add');

  return (
    <div className="p-4">
      <div className="pt-10 mt-3">
        <div className="flex justify-start gap-4">
          <button
            className={`btn p-2 hover:text-[#AB3430] transition-all duration-300 ${view === 'add' ? 'text-[#AB3430] border-b border-[#AB3430]' : 'border-b border-transparent'
              }`}
            onClick={() => setView('add')}
          >
            Checkout Product in Store
          </button>

          <button
            className={`btn p-2 hover:text-[#AB3430] transition-all duration-300 ${view === 'view' ? 'text-[#AB3430] border-b border-[#AB3430]' : 'border-b border-transparent'
              }`}
            onClick={() => setView('view')}
          >
            View Sold Products in Store
          </button>

        </div>

        {view === 'add' && <SelfCheckout />}
        {view === 'view' && <GetCheckoutAdmin />}
      </div>
    </div>
  );
};

export default CheckoutAdmin;
