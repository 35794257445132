import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import {
  getAllCategoryApi,
  getAllProductApi,
  getProductByCategoryApi,
  getAllSubcategoriesApi
} from '../../Apis/Api';
import Card from '../component/Card';
import whatsapp from "../../assets/icons/whatsapp.png"

const Category = () => {
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [filterLoading, setFilterLoading] = useState(false); // Loading state for filters
  const [error, setError] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(true); // State for filter toggle
  const [isCategoryVisible, setIsCategoryVisible] = useState(true);
  const [priceRange, setPriceRange] = useState({ min: 0, max: 10000 });
  const [isPriceRangeVisible, setIsPriceRangeVisible] = useState(false);
  const [isSubcategoryVisible, setIsSubcategoryVisible] = useState(true); // Control visibility
  const [subcategories, setSubcategories] = useState([]); // Store fetched subcategories
  const [selectedSubcategories, setSelectedSubcategories] = useState([]); // Track selected subcategories
  const [validSubCategories, setValidCategories] = useState([])
  const handlePriceChange = (e, type) => {
    const newValue = parseInt(e.target.value);
    setPriceRange((prevRange) => ({
      ...prevRange,
      [type]: newValue,
    }));
  };

  const fetchData = async () => {
    try {
      const categoryResponse = await getAllCategoryApi();
      const productResponse = await getAllProductApi();

      // Filter the products to include only those with showProductinSite = true
      const filteredProducts = productResponse.data.products.filter(
        (product) => product.showProductinSite
      );

      setCategories(categoryResponse.data.categories || []);
      setProducts(filteredProducts || []);
      setFilteredProducts(filteredProducts || []); // Filtered products
      setLoading(false);
    } catch (err) {
      setError('Failed to load data');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // Apply price filter
    let filtered = products.filter(
      (product) =>
        product.discountedPrice >= priceRange.min && product.discountedPrice <= priceRange.max
    );

    // Filter by selected categories
    if (selectedCategories.length > 0) {
      filtered = filtered.filter((product) =>
        selectedCategories.includes(product.category)
      );
    }

    // Filter by selected subcategories
    if (selectedSubcategories.length > 0) {
      filtered = filtered.filter((product) =>
        selectedSubcategories.includes(product.subcategory)
      );
    }

    setFilteredProducts(filtered);
  }, [priceRange, selectedCategories, selectedSubcategories, products]);

  useEffect(() => {
    // Apply price filter
    let filtered = products.filter(
      (product) =>
        product.discountedPrice >= priceRange.min && product.discountedPrice <= priceRange.max
    );

    if (selectedCategories.length > 0) {
      filtered = filtered.filter((product) =>
        selectedCategories.includes(product.category)
      );
    }

    setFilteredProducts(filtered);
  }, [priceRange, selectedCategories, products]);
  const handleSearch = () => {
    if (searchTerm) {
      const lowerSearchTerm = searchTerm.toLowerCase();
      setFilteredProducts(
        products.filter((product) =>
          product.name.toLowerCase().includes(lowerSearchTerm)
        )
      );
    } else {
      setFilteredProducts(
        selectedCategories.length > 0 ? filteredProducts : products
      );
    }
  };

  useEffect(()=>{
    console.log('subcategories: ', subcategories);
    if(selectedCategories.length && subcategories.length){
      let results = [];
      selectedCategories.forEach(id=>{
        const res = subcategories.filter((subcat) => subcat.category._id === id);
        console.log('subcategories: ', subcategories);
        console.log('res: ', res);
        results.push(...res)
      })
      console.log('results: ', results);
      setValidCategories(results)
    }
  },[selectedCategories,subcategories])

  const handleCategoryChange = async (categoryId) => {
    const updatedSelectedCategories = selectedCategories.includes(categoryId)
      ? selectedCategories.filter((id) => id !== categoryId)
      : [...selectedCategories, categoryId];

    setSelectedCategories(updatedSelectedCategories);

    if (updatedSelectedCategories.length === 0) {
      setFilteredProducts(products); // Show all products when no categories are selected
      return;
    }

    setFilterLoading(true);

    try {
      const promises = updatedSelectedCategories.map((id) =>
        getProductByCategoryApi(id)
      );
      const results = await Promise.all(promises);

      // Combine and filter products with `showProductinSite: true`
      const uniqueProducts = [
        ...new Map(
          results
            .flatMap((response) => response.data.products || [])
            .filter((product) => product.showProductinSite) // Filter here
            .map((product) => [product._id, product])
        ).values(),
      ];

      setFilteredProducts(uniqueProducts);
    } catch (err) {
      setError('Failed to fetch products by category');
    } finally {
      setFilterLoading(false);
    }
  };


  useEffect(() => {
    const fetchSubcategories = async () => {
      try {
        const response = await getAllSubcategoriesApi();
        console.log('Subcategory Response:', response.data); // Log API response
        if (Array.isArray(response.data)) {
          setSubcategories([...response.data]); // Spread to force re-render
        } else if (response.data && Array.isArray(response.data.subCategories)) {
          setSubcategories([...response.data.subCategories]);
        } else if (response.data && Array.isArray(response.data.data)) {
          setSubcategories([...response.data.data]); // Handles nested subcategories
        } else {
          console.warn('Unexpected response structure:', response);
        }
      } catch (error) {
        console.error('Error fetching subcategories:', error);
      }
    };

    fetchSubcategories();
  }, []);

  const handleSubcategoryChange = (subcategoryId) => {
    setSelectedSubcategories((prevSelected) => 
      prevSelected.includes(subcategoryId) 
        ? prevSelected.filter((id) => id !== subcategoryId) 
        : [...prevSelected, subcategoryId]
    );
  };

  if (loading)
    return (
      <div className="flex justify-center items-center h-screen">
        <ReactLoading type="spokes" color="#4A90E2" height={100} width={100} />
      </div>
    );

  if (error) return <p>{error}</p>;

  return (
    <div className="p-4 mx-auto lg:flex gap-6">
      {/* Filter Section */}
      <div
        className={` bg-white p-1 mb-5  rounded-lg  relative transform transition-transform duration-300  ${isFilterOpen ? 'translate-x-0 lg:w-1/4 border border-gray-200 shadow' : '-translate-x-full '
          }`}
      >
        {/* Toggle arrows */}
        <div className={`absolute top-1  transform -translate-x-1/2 flex gap-2   ${!isFilterOpen ? 'right-[-30px]' : ' lg:right-[-48px]  right-[-10px] '}  `}>
          {isFilterOpen ? (
            <button
              onClick={() => setIsFilterOpen(false)}
              className="p-1 bg-[#AB3430] rounded-r-lg hover:bg-red-500 "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="white"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M7.72 12.53a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L9.31 12l6.97 6.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5Z"
                  clipRule="evenodd"
                />
              </svg>

            </button>
          ) : (
            <div className="relative group">
              <button
                onClick={() => setIsFilterOpen(true)}
                className="p-1 bg-[#AB3430] rounded-r-lg hover:bg-red-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="white"
                  className="w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              {/* Tooltip */}
              <span className="absolute  top-[-10px] transform -translate-y-1/2 ml-2 px-2 py-1 text-sm text-white bg-gray-800 rounded-md opacity-0 group-hover:opacity-100 transition-opacity">
                Filter
              </span>
            </div>

          )}
        </div>
        <div className={isFilterOpen ? 'block' : 'hidden'}>



          <h3 className={`text-lg font-semibold  px-4 p-1`}>Filter</h3>

          <div className='border border-gray-200 rounded-lg m-2'>
            <h3
              className={`text-lg font-semibold mb-4 bg-[rgb(171,52,48)] text-white rounded-t-lg p-2 flex justify-between items-center cursor-pointer`}
              onClick={() => setIsCategoryVisible(!isCategoryVisible)}
            >
              <div>Filter by Category</div>
              {/* Icons for toggle */}
              {isCategoryVisible ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z"
                    clipRule="evenodd"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z"
                    clipRule="evenodd"
                  />
                </svg>
              )}
            </h3>

            {/* Categories List */}
            {isCategoryVisible && (
              <div className="pl-4">
                {categories.map((category) => (
                  <div key={category._id} className="mb-2">
                    <label className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        checked={selectedCategories.includes(category._id)}
                        onChange={() => handleCategoryChange(category._id)}
                      />
                      <span>{category.name}</span>
                    </label>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className='border border-gray-200 rounded-lg m-2'>
            <h3
              className={`text-lg font-semibold mb-4 bg-[rgb(171,52,48)] text-white rounded-t-lg p-2 flex justify-between items-center cursor-pointer`}
              onClick={() => setIsSubcategoryVisible(!isSubcategoryVisible)}
            >
              <div>Filter by Subcategory</div>
              {isSubcategoryVisible ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                  <path fillRule="evenodd" d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z" clipRule="evenodd" />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                  <path fillRule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clipRule="evenodd" />
                </svg>
              )}
            </h3>

            {isSubcategoryVisible && (
              <div className="pl-4">
                {/* {subcategories && Array.isArray(subcategories) && subcategories.map((subcategory) => (
                  <div key={subcategory._id} className="mb-2">
                    <label className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        checked={selectedSubcategories.includes(subcategory._id)}
                        onChange={() => handleSubcategoryChange(subcategory._id)}
                      />
                      <span>{subcategory.name}</span>
                    </label>
                  </div>
                ))} */}
                {validSubCategories && Array.isArray(validSubCategories) && validSubCategories.map((subcategory) => (
                  <div key={subcategory._id} className="mb-2">
                    <label className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        checked={selectedSubcategories.includes(subcategory._id)}
                        onChange={() => handleSubcategoryChange(subcategory._id)}
                      />
                      <span>{subcategory.name}</span>
                    </label>
                  </div>
                ))}

              </div>
            )}
          </div>


          <div className='border border-gray-200 rounded-lg m-2'>
            <h3
              className={`text-lg font-semibold mb-4 bg-[#AB3430] text-white rounded-t-lg p-2 flex justify-between items-center cursor-pointer`}
              onClick={() => setIsPriceRangeVisible(!isPriceRangeVisible)}
            >
              <div>Filter by Price</div>
              {isPriceRangeVisible ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                  <path
                    fillRule="evenodd"
                    d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z"
                    clipRule="evenodd"
                  />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                  <path
                    fillRule="evenodd"
                    d="M12.53 16.28a.75.75 0 0 1 1.06 0l7.5-7.5a.75.75 0 1 1-1.06-1.06L12 14.69l-6.97-6.97a.75.75 0 0 1-1.06 1.06l7.5 7.5Z"
                    clipRule="evenodd"
                  />
                </svg>
              )}
            </h3>
            {isPriceRangeVisible && (
              <div className="pl-4 pb-4 px-2">
                <label className="block mb-2 text-lg font-medium text-gray-400">Price Range</label>
                <label className="block mb-2">Minimum Price:</label>
                <input
                  type="range"
                  min="0"
                  max="10000"
                  step="10"
                  color='red'
                  value={priceRange.min}
                  onChange={(e) => handlePriceChange(e, 'min')}
                  className="w-full"
                />
                <label className="block mb-2">Maximum Price:</label>
                <input
                  type="range"
                  min="0"
                  max="10000"
                  step="10"
                  value={priceRange.max}
                  onChange={(e) => handlePriceChange(e, 'max')}
                  className="w-full"
                />
                <div className="flex justify-between">
                  <span>Rs {priceRange.min}</span>
                  <span>Rs {priceRange.max}</span>
                </div>
              </div>
            )}
          </div>

        </div>
      </div>

      {/* Products Section */}
      <div
        className={`transition-all duration-300 ${isFilterOpen ? 'lg:w-3/4 pl-[15px]' : 'w-[100%] '
          }`}
      >
        <div className="flex justify-between items-center mb-4 lg:pt-0 pt-5">
          {/* Search Input */}
          <div className="relative w-full mr-4">
            <input
              type="text"
              placeholder="Search products..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full p-2 pl-10 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-400"
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-5 h-5 text-gray-500 absolute left-3 top-1/2 transform -translate-y-1/2"
            >
              <path
                fillRule="evenodd"
                d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z"
                clipRule="evenodd"
              />
            </svg>
          </div>

          {/* Search Button */}

          <button
            onClick={() => handleSearch()}
            className="flex items-center gap-2 px-4 py-2 bg-[#AB3430]  text-white rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400"
          >
            Search
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-5 h-5"
            >
              <path d="M8.25 10.875a2.625 2.625 0 1 1 5.25 0 2.625 2.625 0 0 1-5.25 0Z" />
              <path
                fillRule="evenodd"
                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.125 4.5a4.125 4.125 0 1 0 2.338 7.524l2.007 2.006a.75.75 0 1 0 1.06-1.06l-2.006-2.007a4.125 4.125 0 0 0-3.399-6.463Z"
                clipRule="evenodd"
              />
            </svg>
          </button>

        </div>

        {filterLoading ? (
          <div className="flex justify-center items-center h-32">
            <ReactLoading type="cylon" color="#4A90E2" height={100} width={100} />
          </div>
        ) : (
          <div className={`transition-all duration-300 ${isFilterOpen ? 'grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 lg:gap-5 pb-10 mb-5' : 'grid grid-cols-1 lg:grid-cols-4 lg:gap-5 pb-10 mb-5'
            }`}>
            {filteredProducts.length > 0 ? (
              filteredProducts.map((product) => (
                <Card key={product._id} product={product} />
              ))
            ) : (
              <p>No products found for selected categories</p>
            )}
          </div>
        )}
      </div>
      <div className='fixed bottom-0 right-0 p-5 hover:cursor-pointer hover:grayscale-90'>
        <a aria-label="Chat on WhatsApp" href="https://wa.me/9848556062">
          <img src={whatsapp} alt="whatsapp" className='h-[60px]' />
        </a>
      </div>
    </div>
  );
};

export default Category;
