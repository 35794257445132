import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { getAllBannersApi, createBannerApi, deleteBannerApi, baseURL } from '../../../Apis/Api'; // Adjust your API imports if needed

const AddBanner = () => {
    const [title, setTitle] = useState('');
    const [image, setImage] = useState(null);
    const [banners, setBanners] = useState([]);

    // Fetch banners when the component mounts
    useEffect(() => {
        const fetchBanners = async () => {
            try {
                const response = await getAllBannersApi();
                console.log(response.data); // Log response structure
                if (response.data.success) {
                    const fetchedBanners = response.data.banners || [];
                    setBanners(fetchedBanners);
                } else {
                    toast.error('Failed to fetch banners');
                }
            } catch (error) {
                console.error('Error fetching banners:', error);
            }
        };
        

        fetchBanners();
    }, []);

    const handleImageChange = (e) => {
        const selectedFile = e.target.files[0];
        setImage(selectedFile);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!title || !image) {
            toast.error('Please provide a title and an image.');
            return;
        }

        const formData = new FormData();
        formData.append('title', title);
        formData.append('image', image);

        try {
            const response = await createBannerApi(formData);

            if (response.data.success) {
                toast.success('Banner created successfully!');
                setTitle('');
                setImage(null);

                // Fetch banners again to update the list
                const bannersResponse = await getAllBannersApi();
                if (bannersResponse.data.success) {
                    setBanners(bannersResponse.data.banners || []);
                }
            } else {
                toast.error(response.data.message || 'Failed to create banner');
            }
        } catch (error) {
            toast.error('An error occurred while creating the banner.');
        }
    };

    const handleDelete = async (bannerId) => {
        try {
            const response = await deleteBannerApi(bannerId);
            if (response.data.success) {
                toast.success('Banner deleted successfully!');
                setBanners(banners.filter((banner) => banner._id !== bannerId));
            } else {
                toast.error('Failed to delete banner');
            }
        } catch (error) {
            toast.error('An error occurred while deleting the banner.');
        }
    };

    return (
        <div className="px-2">
            <div className="mx-auto">
                <div className="text-gray-600 font-bold text-3xl mt-5">Create New Banner</div>

                {/* Display total banners */}
                <div className="total-category p-2 gap-4 bg-[#38ab3033] rounded-md w-fit flex justify-between items-center mt-5">
                    <div>
                        <h2 className="flex gap-2 items-center text-[#30ab36]">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="size-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                                />
                            </svg>
                            Total Banners:
                        </h2>
                        <h1 className="text-[#30ab36] text-[25px] font-bold">{banners.length}</h1>
                    </div>
                </div>

                {/* Display the form to create a new banner */}
                <form onSubmit={handleSubmit} className="mt-5">
                    <div className="mt-5">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Title</label>
                        <input
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder="Enter banner title"
                            className="shadow appearance-none border rounded lg:w-[50%] py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2">Upload Image</label>
                        <input
                            type="file"
                            onChange={handleImageChange}
                            className="text-gray-500 lg:w-[50%] font-medium text-base bg-gray-100 file:cursor-pointer cursor-pointer file:border-0 file:py-2.5 file:px-4 file:mr-4 file:bg-gray-800 file:hover:bg-gray-700 file:text-white rounded"
                        />
                    </div>
                    <button
                        className="bg-[#AB3430] hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-3"
                        type="submit"
                    >
                        Create Banner
                    </button>
                </form>

                {/* Display banners or message if no banners */}
                {banners.length > 0 ? (
                    <div className="mt-5">
                        <table className="min-w-full bg-white border border-gray-200">
                            <thead>
                                <tr className="bg-gray-100">
                                    <th className="py-2 px-4 text-left">Title</th>
                                    <th className="py-2 px-4 text-left">Image</th>
                                    <th className="py-2 px-4 text-left">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {banners.map((banner) => (
                                    <tr key={banner._id} className="border-b">
                                        <td className="py-2 px-4">{banner.title}</td>
                                        <td className="py-2 px-4">
                                            <img
                                                src={`${baseURL}/${banner.image}?alt=media`}
                                                alt={banner.title}
                                                className="w-24 h-24 object-cover"
                                            />
                                        </td>
                                        <td className="py-2 px-4">
                                            <button
                                                onClick={() => handleDelete(banner._id)}
                                                className="text-red-600 hover:text-red-800"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="1.5"
                                                    stroke="currentColor"
                                                    className="w-6 h-6"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M6 18.75A2.25 2.25 0 008.25 21h7.5A2.25 2.25 0 0018 18.75v-10.5H6v10.5zM9 6.75V5.25A1.5 1.5 0 0110.5 3.75h3A1.5 1.5 0 0115 5.25v1.5M4.5 6.75h15"
                                                    />
                                                </svg>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div className="mt-5 text-gray-500 text-center">No banner added.</div>
                )}
            </div>
        </div>
    );
};

export default AddBanner;
