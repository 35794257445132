import React, { useState, useEffect } from 'react'
import WeeklySale from '../component/WeeklySale'
import OrderList from './orderHistory/OrderList'
import { getAllProductApi, getAllCategoryApi, getAllSubcategoriesApi } from '../../Apis/Api'; // Adjust the path as needed



const Dashboard = () => {
  const [totalProducts, setTotalProducts] = useState(0);
  const [totalCategories, setTotalCategories] = useState(0);
  const [totalSubcategories, setTotalSubcategories] = useState(0);

  useEffect(() => {
    // Fetch total products
    getAllProductApi()
      .then((response) => {
        setTotalProducts(response.data); // Assuming `data` is an array of products
      })
      .catch((error) => console.error("Error fetching products:", error));

    // Fetch total categories
    getAllCategoryApi()
      .then((response) => {
        setTotalCategories(response.data); // Assuming `data` is an array of categories
      })
      .catch((error) => console.error("Error fetching categories:", error));

    // Fetch total subcategories
    getAllSubcategoriesApi()
      .then((response) => {
        setTotalSubcategories(response.data); // Assuming `data` is an array of subcategories
      })
      .catch((error) => console.error("Error fetching subcategories:", error));
  }, []);

  return (
    <div>
      <div className='grid lg:grid-cols-4 grid-cols-2 mt-[60px] gap-5 px-5'>
      <div className="total-product p-2 gap-4 bg-gray-100 rounded-md flex justify-between items-center mt-5 w-full">
        <div>
          <h2 className="flex gap-2 items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="m7.875 14.25 ..." />
            </svg>
            Total product:
          </h2>
          <h1 className="text-gray-400 text-[25px] font-bold">{totalProducts.length}</h1>
        </div>
      </div>
      <div className="total-product p-2 gap-4 bg-gray-100 rounded-md flex justify-between items-center mt-5 w-full">
        <div>
          <h2 className="flex gap-2 items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="m7.875 14.25 ..." />
            </svg>
            Total SubCategory:
          </h2>
          <h1 className="text-gray-400 text-[25px] font-bold">{totalSubcategories.length}</h1>
        </div>
      </div>
      <div className="total-product p-2 gap-4 bg-gray-100 rounded-md flex justify-between items-center mt-5 w-full">
        <div>
          <h2 className="flex gap-2 items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="m7.875 14.25 ..." />
            </svg>
            Total Category:
          </h2>
          <h1 className="text-gray-400 text-[25px] font-bold">{totalCategories.length}</h1>
        </div>
      </div>


      </div>
      <div className='grid grid-cols-2 gap-5 mt-5'>
        <WeeklySale />
      </div>
      <div>
        <div className='p-3 px-5'>Dievery Status</div>
       <OrderList/>

      </div>

    </div>
  )
}

export default Dashboard