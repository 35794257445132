import React, { useEffect, useState } from 'react';
import { baseURL, getAllGalleryApi } from '../../Apis/Api'; // Adjust the path as per your project structure
import whatsapp from "../../assets/icons/whatsapp.png"

const Gallery = () => {
  const [gallery, setGallery] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchGallery = async () => {
      try {
        const response = await getAllGalleryApi();
        setGallery(response.data.gallery || []); // Assuming the API response structure
        setLoading(false);
      } catch (err) {
        setError('Failed to load gallery');
        setLoading(false);
      }
    };

    fetchGallery();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <>
      <div className="p-5 text-center bg-[#E3DCD7]">
        <div className="font-bold text-3xl valky">Gallery</div>
      </div>
      <div className="container mx-auto">
        <div className="p-5">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {gallery.map((item, index) => (
              <div key={index} className="relative group">
                <img
                  className="h-auto max-w-full rounded-lg transition-transform duration-300 group-hover:scale-105"
                  src={`${baseURL}/${item.image}`} // Use baseURL for dynamic URL
                  alt={item.title || 'Gallery Image'}
                />
                <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 flex items-center justify-center rounded-lg transition-opacity duration-300">
                  <span className="text-white font-bold text-lg">{item.title}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='fixed bottom-0 right-0 p-5 hover:cursor-pointer hover:grayscale-90'>
<a aria-label="Chat on WhatsApp" href="https://wa.me/9848556062"> 
        <img src={whatsapp} alt="whatsapp"  className='h-[60px]'/>
        </a>
        </div>
    </>
  );
};

export default Gallery;
