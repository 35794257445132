import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { decreaseQuantity, increaseQuantity, removeFromCart, clearCart } from "../store/cartSlice"; // Import clearCart
import { createOrderApi } from "../../Apis/Api";
import toast from "react-hot-toast";

const AddToCart = () => {
  const dispatch = useDispatch();

  // Redux selectors
  const { cart } = useSelector((state) => state.cartReducer);
  const user = JSON.parse(localStorage.getItem("user"));

  // Local states
  const [shippingAddress, setShippingAddress] = useState("");

  // Utility functions for calculations
  const calculateTotalAmount = () =>
    cart.reduce((total, item) => total + item.discountedPrice * item.quantity, 0);  

  const calculateShippingCharge = (totalAmount) =>
    totalAmount >= 1000 ? 200 : 500;

  const totalAmount = calculateTotalAmount();
  const shippingCharge = calculateShippingCharge(totalAmount);
  const totalPriceWithShipping = totalAmount + shippingCharge;
  const totalQuantity = cart.reduce((total, item) => total + item.quantity, 0);

  // Event handlers
  const handleBuyNow = async () => {
    if (!shippingAddress.trim()) {
      toast.error("Please enter a valid shipping address.");
      return;
    }

    if (!user?.id) {
      toast.error("User ID is missing. Please log in again.");
      return;
    }

    const orderData = {
      userId: user.id,
      items: cart.map((item) => ({
        productId: item.id,
        name: item.name,
        colors: item.color,
        sizes: item.size,
        quantity: item.quantity,
        discountedPrice: item.discountedPrice,
      })),
      shippingAddress,
      totalAmount,
      shippingCharge,
      totalPriceWithShipping,
      status: "pending",
    };
    console.log(orderData);

    try {
      const response = await createOrderApi(orderData);
      toast.success(response.data.message || "Order placed successfully!");

      // Clear the cart after successful order placement
      dispatch(clearCart());
    } catch (error) {
      console.error("Error placing order:", error);
      toast.error(
        error.response?.data?.message ||
          "Failed to place order. Please try again."
      );
    }
  };

  return (
    <div className="container mx-auto">
      <h1 className="text-center p-5 bg-gray-200 font-bold text-gray-600 valky">
        Shopping Cart
      </h1>

      {cart.length === 0 ? (
        <div className="text-center py-10">
          <h3 className="text-red-500 text-3xl font-bold pb-10 valky">
            Your cart is empty!
          </h3>
          <Link
            to="/category"
            className="btn bg-red-600 hover:bg-red-700 text-white rounded-md text-lg px-4 py-2 "
          >
            Continue Shopping
          </Link>
        </div>
      ) : (
        <div className="grid lg:grid-cols-3 gap-5 p-5">
          {/* Cart Items Section */}
          <div className="col-span-2">
            <table className="w-full text-sm text-left text-gray-500 bg-gray-100">
              <thead className="bg-gray-300">
                <tr>
                  <th className="px-6 py-3">Product</th>
                  <th className="px-6 py-3">Size</th>
                  <th className="px-6 py-3">Color</th>
                  <th className="px-6 py-3">Price</th>
                  <th className="px-6 py-3">Quantity</th>
                  <th className="px-6 py-3">Subtotal</th>
                </tr>
              </thead>
              <tbody>
                {cart.map((item) => (
                  <tr key={item.id} className="bg-white hover:bg-gray-50">
                    <td className="flex items-center gap-4 px-6 py-4">
                      <img
                        src={item.productImage}
                        alt="product"
                        className="h-20 w-16 object-cover"
                      />
                      <div>
                        <p className="font-medium">{item.name}</p>
                        <p className="text-sm text-gray-500">
                          {item.category?.name}
                        </p>
                      </div>
                    </td>
                    <td className="px-6 py-4">{item.size}</td>
                    <td className="px-6 py-4">{item.color}</td>
                    <td className="px-6 py-4">NPR. {item.discountedPrice}</td>
                    <td className="px-6 py-4 flex items-center gap-2">
                      <button
                        onClick={() =>
                          dispatch(decreaseQuantity({ itemId: item.id }))
                        }
                        className="px-2 py-1 bg-gray-300 rounded"
                      >
                        -
                      </button>
                      <span>{item.quantity}</span>
                      <button
                        onClick={() =>
                          dispatch(increaseQuantity({ itemId: item.id }))
                        }
                        className="px-2 py-1 bg-gray-300 rounded"
                      >
                        +
                      </button>
                      <button
                        onClick={() =>
                          dispatch(removeFromCart({ itemId: item.id }))
                        }
                        className="ml-3 text-red-500 hover:underline"
                      >
                        Remove
                      </button>
                    </td>
                    <td className="px-6 py-4">
                      NPR. {(item.discountedPrice * item.quantity).toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Order Summary Section */}
          <div className="bg-gray-50 p-5 rounded shadow-md">
            <h3 className="font-bold text-lg mb-4">Order Summary</h3>
            <div className="flex justify-between text-gray-600">
              <span>Total Items:</span>
              <span>{totalQuantity}</span>
            </div>
            <div className="flex justify-between text-gray-600 mt-2">
              <span>Shipping Charge:</span>
              <span>NPR. {shippingCharge.toFixed(2)}</span>
            </div>
            <hr className="my-3" />
            <div className="flex justify-between font-bold text-xl">
              <span>Total Price:</span>
              <span>NPR. {totalPriceWithShipping.toFixed(2)}</span>
            </div>
            <hr className="my-3" />
            <label htmlFor="shippingAddress" className="block text-gray-600 mb-2">
              Shipping Address
            </label>
            <input
              id="shippingAddress"
              type="text"
              className="w-full p-2 border border-gray-300 rounded mb-4"
              value={shippingAddress}
              onChange={(e) => setShippingAddress(e.target.value)}
              placeholder="Enter your address"
            />
            <button
              onClick={handleBuyNow}
              className="w-full bg-red-600 text-white py-2 rounded hover:bg-red-700"
            >
              Buy Now
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddToCart;