import React, { useState, useEffect } from 'react';
import { Rating, ThinStar } from '@smastrom/react-rating';
import '@smastrom/react-rating/style.css';
import { updateRatingApi, getProductsByRatingApi } from '../../Apis/Api';
import { toast } from 'react-hot-toast';

// Declare the styles outside the component
const myStyles = {
  itemShapes: ThinStar,
  activeFillColor: '#000000',
  inactiveFillColor: '#E3DCD7',
};

function MyRating({ productId, userId }) {
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState(''); // To display success or error messages
  const [productRating, setProductRating] = useState(null);

  useEffect(() => {
    // Fetch the product rating when component mounts
    const fetchProductRating = async () => {
      try {
        const response = await getProductsByRatingApi(userId, productId);
        if (response.success) {
          const product = response.products[0]; // Assuming a single product is returned
          setProductRating(product.rating); // Set the product rating from API response
        }
      } catch (error) {
        console.log('Error fetching product rating');
      }
    };

    fetchProductRating();
  }, [productId, userId]);

  // Handle rating change and API call
  const handleRatingChange = async (newRating) => {
    setRating(newRating); // Update the state immediately
    try {
      const response = await updateRatingApi(productId, { rating: newRating });
      toast.success('Rating updated successfully!'); // Success message
      console.log('Updated Product:', response); // For debugging
    } catch (error) {
      toast.error('Error updating rating:', error);
      setFeedback('Failed to update rating.'); // Error message
    }
  };

  return (
    <div className="lg:flex lg:justify-between">
      <Rating
        style={{ width: '100px' }}
        value={rating}
        onChange={handleRatingChange}
        itemStyles={myStyles}
      />
      <p className="text-[#AB3430]">Your rating: {rating}</p>

      {/* Display the product's current rating */}
      {productRating !== null && (
        <p className="text-sm text-gray-500 mt-2">Current product rating: {productRating}</p>
      )}

      {feedback && <p className="text-sm text-gray-500 mt-2">{feedback}</p>}
    </div>
  );
}

export default MyRating;
