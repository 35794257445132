import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { getAllCheckouts } from '../../../Apis/Api';
import * as XLSX from 'xlsx';

const GetCheckoutAdmin = () => {
  const [checkouts, setCheckouts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchCheckouts();
  }, []);

  const fetchCheckouts = async () => {
    try {
      const { data } = await getAllCheckouts(); // Adjust the API endpoint as needed
      if (data.success) {
        setCheckouts(data.data);
      } else {
        toast.error('Failed to fetch checkout records.');
      }
    } catch (error) {
      console.error(error);
      toast.error('An error occurred while fetching checkout records.');
    } finally {
      setLoading(false);
    }
  };

  // Function to export data to Excel
  const exportToExcel = () => {
    const checkoutData = checkouts.map((checkout) => {
      return {
        'Date & Time': `${new Date(checkout.createdAt).toLocaleDateString()} ${new Date(checkout.createdAt).toLocaleTimeString()}`,
        'Payment Method': checkout.paymentMethod.charAt(0).toUpperCase() + checkout.paymentMethod.slice(1),
        'Total Amount': `Npr. ${checkout.totalAmount}`,
        'Products': checkout.products.map(product => 
          `Name: ${product.productId?.name || product.name}, Size: ${product.size}, Color: ${product.color}, Quantity: ${product.quantity}, Price: Npr. ${product.discountedPrice}`).join(' | '),
      };
    });

    const ws = XLSX.utils.json_to_sheet(checkoutData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Checkouts');

    // Save the file
    XLSX.writeFile(wb, 'Checkouts.xlsx');
  };

  return (
    <div className="max-w-6xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-bold text-center mb-6">Self-Checkout Records</h2>

      {loading ? (
        <div className="text-center text-blue-500">Loading...</div>
      ) : checkouts.length === 0 ? (
        <div className="text-center text-gray-500">No checkout records found.</div>
      ) : (
        <>
          <button 
            onClick={exportToExcel} 
            className="mb-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            Export to Excel
          </button>

          <table className="w-full border-collapse border border-gray-300">
            <thead className="bg-gray-200">
              <tr>
                <th className="border border-gray-300 px-4 py-2">#</th>
                <th className="border border-gray-300 px-4 py-2">Date & Time</th>
                <th className="border border-gray-300 px-4 py-2">Product Details</th>
                <th className="border border-gray-300 px-4 py-2">Payment Method</th>
                <th className="border border-gray-300 px-4 py-2">Total Amount</th>
              </tr>
            </thead>
            <tbody>
              {checkouts.map((checkout, index) => (
                <tr key={checkout._id} className="hover:bg-gray-100">
                  <td className="border border-gray-300 px-4 py-2 text-center">
                    {index + 1}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 text-center">
                    {new Date(checkout.createdAt).toLocaleDateString()} <br />
                    {new Date(checkout.createdAt).toLocaleTimeString()}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {checkout.products.map((product, i) => (
                      <div key={i} className="mb-4">
                        <p><strong>Name:</strong> {product.productId?.name || product.name}</p>
                        <p><strong>Size:</strong> {product.size}</p>
                        <p><strong>Color:</strong> {product.color}</p>
                        <p><strong>Quantity:</strong> {product.quantity}</p>
                        <p><strong>Price:</strong> Npr. {product.discountedPrice}</p>
                      </div>
                    ))}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 text-center">
                    {checkout.paymentMethod.charAt(0).toUpperCase() + checkout.paymentMethod.slice(1)}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 text-center">Npr. {checkout.totalAmount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default GetCheckoutAdmin;
