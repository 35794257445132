import React, { useState, useEffect } from 'react';
import { baseURL } from '../../../../Apis/Api'; // Adjust the import path based on your project structure

const ProductModal = ({ product, categories, subCategories, isOpen, onClose }) => {
  // State to manage the current large image
  const [largeImage, setLargeImage] = useState(null);

  // Effect to reset largeImage when the product changes
  useEffect(() => {
    if (isOpen && product && product.images && product.images.length > 0) {
      setLargeImage(product.images[0]); // Reset large image when product or modal opens
    }
  }, [product, isOpen]);

  // Early return if the modal is not open or product is null
  if (!isOpen || !product) return null;

  // Handler to change the large image
  const handleImageClick = (image) => {
    setLargeImage(image);
  };

  // Create a map for categories
  const categoryMap = categories.reduce((map, category) => {
    map[category._id] = category.name;
    return map;
  }, {});

  // Create a map for subcategories
  const subCategoryMap = subCategories.reduce((map, subCategory) => {
    map[subCategory._id] = subCategory.name; // Store subcategory name
    return map;
  }, {});

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-11/12 md:w-3/4 lg:w-2/3 xl:w-3/5 p-6 overflow-hidden flex">
        {/* Image Gallery Section */}
        <div className="flex-shrink-0 w-[300px] flex flex-col">
          {/* Center Large Image */}
          <div className={`flex justify-center mb-4 ${product.images.length > 3 ? '' : ''}`}>
            <img 
              src={`${baseURL}/${largeImage}`} 
              alt="Large display" 
              className={`rounded-lg shadow-md ${product.images.length > 3 ? 'h-[300px] w-auto' : 'h-[400px] w-full'} object-cover`} 
            />
          </div>

          {/* Thumbnail Images */}
          {product.images.length > 1 && (
            <div className="flex flex-row gap-2 overflow-x-auto">
              {product.images.map((image, index) => (
                <img
                  key={index}
                  src={`${baseURL}/${image}`} // Prepend the public path
                  alt={`Thumbnail ${index + 1}`}
                  className='h-[80px] w-auto cursor-pointer rounded-lg hover:border-2 hover:border-red-400 transition duration-200'
                  onClick={() => handleImageClick(image)} // Prepend the public path
                />
              ))}
            </div>
          )}
        </div>

        {/* Product Information Section */}
        <div className="flex-grow pl-6">
          <h2 className="text-2xl font-bold mb-4 text-gray-800">{product.name}</h2>
          <p className="text-gray-700 mb-2"><strong>Description:</strong> {product.description}</p>
          <p className="text-gray-700 mb-2"><strong>Price:</strong> ${product.price}</p>
          <p className="text-gray-700 mb-2"><strong>Category:</strong> {categoryMap[product.category]}</p>
          <p className="text-gray-700 mb-2"><strong>Subcategory:</strong> {subCategoryMap[product.subcategory] || 'N/A'}</p> {/* Added subcategory */}
          <p className="text-gray-700 mb-4"><strong>Show on Site:</strong> {product.showProductinSite ? 'Yes' : 'No'}</p>

          <h3 className="text-xl font-semibold mb-2">Sizes:</h3>
          <div className="grid grid-cols-1 gap-2 mb-4">
            {product.sizes.map((size, index) => (
              <div key={index} className="border rounded p-2 bg-gray-50">
                <strong>{size.size}</strong>
                {size.colors.map((color, colorIndex) => (
                  <div key={colorIndex} className="ml-4">
                    <p>{color.color} - Quantity: {color.quantity}</p>
                  </div>
                ))}
              </div>
            ))}
          </div>

          <button onClick={onClose} className="mt-4 px-4 py-2 bg-indigo-600 text-white rounded shadow hover:bg-indigo-700 transition">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductModal;
