import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import { getProductByIdApi, getAllCategoryApi, createOrderApi} from "../../Apis/Api";
import MyRating from "../component/Rating";
import ImageGallery from "./ImageGallery";
import GetAllProduct from "../Home/product/GetRecentViewedProducts";
import { addToCart } from "../store/cartSlice";
import GetRecentViewedProducts from "../Home/product/GetRecentViewedProducts";
import GetNewProduct from "../Home/product/GetNewProduct";



const ViewProduct = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [count, setCount] = useState(1);
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [categories, setCategories] = useState([]);
  const dispatch = useDispatch();
  const tax = 200;
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shippingAddress, setShippingAddress] = useState('');


  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    setUser(storedUser);
  }, []);


  useEffect(() => {
    // Fetch categories and product data
    const fetchCategoriesAndProduct = async () => {
      try {
        const [categoryResponse, productResponse] = await Promise.all([
          // Fetch categories (assuming a function to fetch categories)
          getAllCategoryApi(),
          getProductByIdApi(id),
        ]);

        if (categoryResponse.status === 200) {
          setCategories(categoryResponse.data.categories || []);
        } else {
          toast.error("Failed to fetch categories.");
        }

        if (productResponse.status === 200) {
          setProduct(productResponse.data.product);
        } else {
          toast.error("Failed to fetch product.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchCategoriesAndProduct();
  }, [id]);

  const increment = () => setCount(count + 1);
  const decrement = () => setCount(count > 1 ? count - 1 : count);

  const handleAddToCart = () => {
    if (!selectedSize || !selectedColor) {
      toast.error("Please select size and color", { position: "top-right" });
      return;
    }

    const cartItem = {
      id: product._id,
      name: product.name,
      images: product.images,
      discountedPrice: product.discountedPrice,
      quantity: count,
      category: product.category,
      productImage: product.productImage,
      size: selectedSize,
      color: selectedColor,
    };
    console.log("Adding to cart:", cartItem);
    dispatch(addToCart(cartItem));
    toast.success("Added to cart successfully!", { position: "top-right", autoClose: 3000 });
  };

  if (!product || !categories.length) return <div>Loading...</div>;

  const totalPrice = (product.price || 0) * count + tax;
  const availableSizes = Array.isArray(product.sizes) ? product.sizes : [];

  // Map category IDs to category names
  const categoryMap = categories.reduce((map, { _id, name }) => {
    map[_id] = name;
    return map;
  }, {});
  const handleBuyNowClick = () => {
    if (!selectedSize) {
      toast.error("Please select a size", { position: "top-right" });
      return;
    }
    if (!selectedColor) {
      toast.error("Please select a color", { position: "top-right" });
      return;
    }
  
    setIsModalOpen(true);
  };
  
  const handleCancelClick = () => {
    setIsModalOpen(false);
  };

  const handleBuyClick = async () => {
    if (!shippingAddress.trim()) {
      toast.error("Please enter a valid shipping address.", { position: "top-right" });
      return;
    }

    if (!user?.id) {
      toast.error("User is not logged in. Please log in first.");
      return;
    }

    const orderData = {
   
      userId: user.id,
      items: 
        {
          productId: product._id,
          name: product.name,
          sizes: selectedSize,
          colors: selectedColor,
          images: product.images,
          quantity: count,
          discountedPrice: product.discountedPrice,
        }
      ,
      shippingAddress,
      totalAmount: count * product.discountedPrice,
      shippingCharge: count * product.discountedPrice >= 1000 ? 200 : 500, // Example shipping logic
      totalPriceWithShipping: count * product.discountedPrice + (count * product.discountedPrice >= 1000 ? 200 : 500),
      status: "pending",
    };

    console.log('orderData: ', orderData);

    try {
      const response = await createOrderApi(orderData);
      toast.success(response.data.message || "Order placed successfully!");
      setIsModalOpen(false); 
    } catch (error) {
      console.error("Error placing order:", error);
      toast.error(
        error.response?.data?.message || "Failed to place order. Please try again."
      );
    }
  };

  // Get category name using the category ID from the product
  const categoryName = categoryMap[product.category];

  //

  return (
    <>
      <div className="container lg:mx-auto py-10">
        <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-10">
          <div className="font-3xl font-bold lg:hidden block px-5">{product.name}</div>
          <ImageGallery images={product.images} />
          <div className="p-5 pr-5 lg:pr-[150px]">
            {/* Display the category name here */}
            <div className="font-medium">{categoryName}</div>
            <h1 className="font-medium">{product.name}</h1>
            <MyRating productId={product._id} userId={user?._id} />
            <div className="pt-5">
              <span className="text-lg text-red-600 font-bold">Rs. {product.discountedPrice}</span>
              <br />
              <span className="text-sm text-gray-500 line-through mr-2 ">Rs. {product.fakePrice}</span>
            </div>
            

            <p className="font-thin pb-5">{product.description}</p>
            <hr />
            <div className="text-green-400 pt-2">In Stock</div>
            <p className="font-thin pb-5">{product.color}</p>

            {/* Size Selector */}
            <label className="font-medium">Size:</label>
            <select
              value={selectedSize}
              onChange={(e) => {
                setSelectedSize(e.target.value);
                setSelectedColor(""); // Reset color when size changes
              }}
              className="border p-2 ml-2"
            >
              <option value="">Select Size</option>
              {availableSizes.map((sizeObj, index) => (
                <option key={index} value={sizeObj.size}>
                  {sizeObj.size}
                </option>
              ))}
            </select>

            {/* Color Selector */}
            {selectedSize && (
              <div className="mt-4">
                <label className="font-medium">Color:</label>
                <select
                  value={selectedColor}
                  onChange={(e) => setSelectedColor(e.target.value)}
                  className="border p-2 ml-2"
                >
                  <option value="">Select Color</option>
                  {availableSizes
                    .find((size) => size.size === selectedSize)?.colors.map((color, idx) => (
                      <option key={idx} value={color.color}>
                        {color.color}
                      </option>
                    ))}
                </select>
              </div>
            )}

            <div>Quantity:</div>
            <div className="grid grid-cols-3 gap-5 mt-3">
              <div className="flex items-center space-x-4">
                <button onClick={decrement} className="bg-gray-100 p-4 py-2 w-12">-</button>
                <span className="text-xl">{count}</span>
                <button onClick={increment} className="bg-gray-100 p-4 py-2 w-12">+</button>
              </div>
              <button onClick={handleAddToCart} className="border px-5 py-3 w-full col-span-2 hover:bg-[#AB3430] hover:text-white">Add to cart</button>
            </div>
            <button 
              className="bg-[#AB3430] text-white px-5 py-3 w-full mt-5 hover:bg-[#782421]" 
              onClick={handleBuyNowClick}
            >
              Buy Now
            </button>

            {isModalOpen && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white p-5 rounded-md w-[90%] sm:w-[400px]">
                  <h2 className="text-xl font-bold mb-4 text-center">Enter Shipping Address</h2>
                  
                  <input
                    type="text"
                    className="w-full p-2 border border-gray-300 rounded mb-4"
                    value={shippingAddress}
                    onChange={(e) => setShippingAddress(e.target.value)}
                    placeholder="Enter your address"
                  />

                  <div className="flex justify-end gap-2">
                    <button onClick={handleCancelClick} className="bg-red-500 text-white px-4 py-2 rounded">Cancel</button>
                    <button onClick={handleBuyClick} className="bg-green-500 text-white px-4 py-2 rounded">Buy</button>
                  </div>
                </div>
              </div>
            )}            <div className="my-5"><hr /></div>
            <h2 className="font-medium">Get it Now</h2>
            {/* Additional product services */}
            <div className="flex gap-2 pt-2">
              <img src="https://anvogue.presslayouts.com/wp-content/uploads/2024/05/product-service-icon1.svg" alt="shiping" />
              <div>
                <h4> Shipping charge</h4>
                <h5 className="text-gray-400"> Shipping Charge on orders over Rs200 to Rs500.</h5>
              </div>
            </div>
            <div className="flex gap-2 pt-2">
              <img src="https://anvogue.presslayouts.com/wp-content/uploads/2024/05/product-service-icon2.svg" alt="shiping" />
              <div>
                <h4>30 - Day Returns</h4>
                <h5 className="text-gray-400">Not impressed? Get a refund. You have 30 days to break our hearts.</h5>
              </div>
            </div>
            <div className="flex gap-2 pt-2">
              <img src="https://anvogue.presslayouts.com/wp-content/uploads/2024/05/product-service-icon3.svg" alt="shiping" />
              <div>
                <h4>Dedicated Support</h4>
                <h5 className="text-gray-400">Support from 8:30 AM to 10:00 PM every day</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="container mx-auto lg:px-10 mt-10">
          <div className="text-[#767676] text-3xl font-medium pb-5">New Product</div>
          <GetNewProduct />
        </div>
      </div>
    </>
  );
};

export default ViewProduct;