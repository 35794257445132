import React, { useEffect, useState } from 'react';
import { getAllProductApi, baseURL } from '../../../Apis/Api';
import MyRating from "../../component/TotalRating";
import { Link, useNavigate } from 'react-router-dom';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import newProduct from "../../../assets/icons/newProduct.png";

const GetNewProduct = () => {
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate(); // Hook for navigation

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await getAllProductApi();
                setProducts(response.data.products);
                setIsLoading(false);
            } catch (err) {
                console.error(err);
                setError("Failed to fetch products");
                setIsLoading(false);
            }
        };

        fetchProducts();
    }, []);

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    // Reverse the array and display the last 4 products
    const displayedProducts = products
        .filter(product => product.showProductinSite) // Filter products
        .reverse() // Reverse the order
        .slice(0, 8); // Get the first 8 from the reversed array

        const responsive = {
            superLargeDesktop: {
                breakpoint: { max: 4000, min: 1500 },
                items: 5,
              },
             LargeDesktops: {
                breakpoint: { max:1500, min: 1024 },
                items: 4,
              },
              desktop: {
                breakpoint: { max: 1024, min: 768 },
                items: 3,
              },
              tablet: {
                breakpoint: { max: 768, min: 464 },
                items: 3,
              },
              mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 2,
              },
          };
        

    return (
        <div>
            {/* View All link */}
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold valky text-gray-600">New Arrivals</h2>
                <button
                    onClick={() => navigate('/category')}
                    className="text-red-500 hover:underline"
                >
                    View All
                </button>
            </div>

            {/* Carousel */}
            <Carousel responsive={responsive} infinite={true} autoPlay={true} autoPlaySpeed={3000}>
                {displayedProducts.map(product => (
                    <Link to={`/viewProduct/${product._id}`} key={product._id} className="">
                        <div className='border border-solid border-gray-200 rounded-lg m-2  p-4 hover:border-red-200 hover:shadow-md transition duration-300 ease-in-out'>
                        <div className=""> {/* Add padding around the image */}
                            <img
                                src={`${baseURL}/${product.images[0]}`} // Ensure this URL is correct
                                alt={product.name}
                                className="w-full rounded-lg object-cover lg:h-[350px] md:h-[200px] h-[200px] hover:scale-105 transition duration-300 ease-in-out"
                            />
                        </div>
                        <div className="text-[#767676] pt-5 font-bold whitespace-nowrap overflow-hidden text-ellipsis">{product.name}</div>
                        <div className='hidden lg:block '>
                        <MyRating productId={product._id} />
                        </div>
                     
                        <div className="lg:mt-2">
                            <span className="lg:text-lg text-red-600 font-bold">Rs. {product.discountedPrice}</span>
                            <br />
                            <span className="text-sm text-gray-500 line-through mr-2">Rs. {product.fakePrice}</span>
                        </div>
                        {/* <div className="absolute top-[0px] lg:left-[0px] left-[0px]">
    <img src={newProduct} alt="flash sell" className="lg:h-[100px] h-[50px] drop-shadow-lg" />
  </div> */}

                        </div>
                    </Link>
                ))}
            </Carousel>
        </div>
    );
};

export default GetNewProduct;
