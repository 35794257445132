import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';

const SalesRoutes = () => {
  const user = JSON.parse(localStorage.getItem('user'));

  if (user && user.isSale) {
    return <Outlet />;
  } else {
    return <Navigate to="/" />;
  }
};

export default SalesRoutes;
