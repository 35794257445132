import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useState, useEffect } from "react";
import { getProfileApi } from "./Apis/Api";
import Home from "./pages/Home/Home";
import Navbar from "./pages/component/Navbar";
import './app.css';
import AdminDashboard from "./pages/admin/AdminDashboard";
import Footer from "./pages/component/Footer";
import AboutUs from "./pages/Home/AboutUs";
import Register from "./pages/loginRegister/Register";
import Profile from "./pages/profile/profile";
import ViewProduct from "./pages/viewProduct/ViewProduct";
import AddCard from "./pages/addToCard/AddCard";
import AdminRoutes from "./PrivateRoutes/AdminRoutes";
import Gallery from "./pages/Home/Gallery";
import PageNotFound from "./pages/component/PageNotFound";
import { Toaster } from "react-hot-toast";
import Category from "./pages/Home/Category";
import FAQ from "./pages/Home/FAQ.jsx";
import SalesDashboard from "./pages/sales/SalesDashboard.jsx";
import SalesRoutes from "./PrivateRoutes/SalesRoutes.jsx";

function App() {
  // Step 1: Check token directly on load
  const [isLoggedIn, setIsLoggedIn] = useState(false); 
  const [loading, setLoading] = useState(true); // New loading state
 
  // Step 2: Check login status and fetch profile on initial render
  useEffect(() => {
    const fetchProfile = async () => {
      if (isLoggedIn) {
        try {
          await getProfileApi();
        } catch (error) {
          console.error('Error fetching profile:', error);
          setIsLoggedIn(false);
        }
      }

      setLoading(false); // Update loading state once we're done
    };

    fetchProfile();
  }, [localStorage.getItem('token')]);

  // Step 3: Show loading indicator while login state is being confirmed
  if (loading) {
    return <div>Loading...</div>; // Show loading message (can be a spinner)
  }

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <Router>
        <Navbar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
        <Routes>
          <>
      
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/register" element={<Register />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/viewProduct" element={<ViewProduct />} />
          <Route path="/viewProduct/:id" element={<ViewProduct />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/category" element={<Category />} />
          <Route path="/addCart" element={<AddCard />} />
          <Route path="/gallery" element={<Gallery />} />
          </>

          {/* Protected Admin Route */}
          <Route element={<AdminRoutes />}>
            <Route path="/admindashboard" element={<AdminDashboard />} />
          </Route>
          <Route element={<SalesRoutes />}>
            <Route path="/salesdashboard" element={<SalesDashboard />} />
          </Route>

          <Route path="*" element={<PageNotFound />} />
        </Routes>

        <Footer />
      </Router>
    </>
  );
}

export default App;
