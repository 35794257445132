import React from 'react'
import AddProduct from '../../addPage'

const AddProducts = () => {
  return (
   
        <div className="mt-3">
   <AddProduct/>
    </div>

  )
}

export default AddProducts