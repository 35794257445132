import React, { useState, useEffect } from 'react';
import { getAllCategoryApi, getAllSubcategoriesApi, addSubCategoryApi } from '../../../Apis/Api';
import toast from 'react-hot-toast';

const AddSubCategory = () => {
  const [name, setName] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getAllCategoryApi();
        setCategories(response.data?.categories || []);
      } catch (err) {
        toast.error('Error fetching categories');
      }
    };

    const fetchSubcategories = async () => {
      try {
        const response = await getAllSubcategoriesApi();
        const fetchedSubcategories = response.data?.subCategories || [];
        setSubcategories(fetchedSubcategories);
        setFilteredSubcategories(fetchedSubcategories);
      } catch (err) {
        toast.error('Error fetching subcategories');
      }
    };

    fetchCategories();
    fetchSubcategories();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await addSubCategoryApi({ name, categoryId });

      if (response.data.success) {
        toast.success(response.data.message);
        setName('');
        setCategoryId('');
        const updatedSubcategories = await getAllSubcategoriesApi();
        const subcategoriesList = updatedSubcategories.data?.subCategories || [];
        setSubcategories(subcategoriesList);
        setFilteredSubcategories(subcategoriesList);
      }
    } catch (err) {
      toast.error(err.response?.data?.message || 'Something went wrong');
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = subcategories.filter((subcategory) =>
      subcategory.name.toLowerCase().includes(query)
    );
    setFilteredSubcategories(filtered);
  };

  return (
    <div className="mx-auto p-5 mt-10">
      <h2 className="text-gray-600 text-3xl font-bold mb-4">Add Subcategory</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block mb-2 font-medium">Subcategory Name</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="shadow appearance-none border rounded w-[50%] py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2 font-medium">Select Category</label>
          <select
            value={categoryId}
            onChange={(e) => setCategoryId(e.target.value)}
            className="shadow appearance-none border rounded w-[50%] py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          >
            <option value="">Select a Category</option>
            {categories.map((category) => (
              <option key={category._id} value={category._id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>

        <button type="submit" className="bg-[#AB3430] hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
          Add Subcategory
        </button>
      </form>

      <div className="mt-8">
        <h3 className="text-lg font-semibold mb-4">Subcategories List</h3>
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearch}
          placeholder="Search subcategories..."
          className="block w-[50%] p-3 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
        />

        <table className="w-full text-sm text-left text-gray-500 border mt-3">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th className="px-6 py-3">SN</th>
              <th className="px-6 py-3">Subcategory Name</th>
              <th className="px-6 py-3">Category</th>
            </tr>
          </thead>
          <tbody>
            {filteredSubcategories?.length > 0 ? (
              filteredSubcategories.map((subcategory, index) => {
                const categoryId = typeof subcategory.category === 'object' ? subcategory.category._id : subcategory.category;
                const categoryName = categories.find(
                  (category) => category._id === categoryId
                )?.name || 'Unknown';

                return (
                  <tr key={subcategory._id} className="bg-white border-b hover:bg-gray-50">
                    <td className="px-6 py-4">{index + 1}</td>
                    <td className="px-6 py-4">{subcategory.name}</td>
                    <td className="px-6 py-4">{categoryName}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="3" className="px-6 py-4 text-center">No subcategories found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AddSubCategory;
